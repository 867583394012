<template>
  <page-view :title="title">
    <div class="user_header_wrapper">
      <a-row :gutter="48" class="row">
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>用户ID</span>
            <a-input placeholder="用户ID" class="filter_input" v-model.trim="queryParam.uid" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>客服ID</span>
            <a-input placeholder="客服ID" class="filter_input" v-model.trim="queryParam.customerUid" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>登录名</span>
            <a-input placeholder="登录名" class="filter_input" v-model.trim="queryParam.username" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>手机号</span>
            <a-input placeholder="手机号" class="filter_input" v-model.trim="queryParam.mobile" />
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="48" class="row">
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>状态</span>
            <a-select v-model="queryParam.hasCustomer" class="filter_input" :allowClear="true" placeholder="请选择分配状态">
              <a-select-option value="true">已分配</a-select-option>
              <a-select-option value="false">未分配</a-select-option>
            </a-select>
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>状态</span>
            <a-select v-model="queryParam.customerVisit" class="filter_input" :allowClear="true" placeholder="请选择回访状态">
              <a-select-option v-for="item in customerVisitArr" :key="item.name" :value="item.value">{{ item.name }}</a-select-option>
            </a-select>
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>距付费天数</span>
            <a-input placeholder="天数" class="filter_input" v-model.trim="lastPayDay" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>距登录天数</span>
            <a-input placeholder="天数" class="filter_input" v-model.trim="lastLoginDay" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ paddingLeft: '0' }">
          <div class="filter_item">
            <a-button
              type="primary"
              class="query_btn"
              style="margin-left:20px"
              @click="
                () => {
                  $refs.table.refresh(false);
                }
              "
              >查询</a-button
            >
            <a-button @click="resetQuery" class="query_btn">重置</a-button>
            <a-button type="primary" class="query_btn" @click="handleBatchAssignUser" :disabled="assign.uids.length < 1">批量分配</a-button>
            <a-button type="primary" class="query_btn" @click="handleBatchClearAssign" :disabled="assign.uids.length < 1">批量清除</a-button>
          </div>
        </a-col>
      </a-row>
    </div>

    <div class="user_table_wrapper">
      <s-table
        ref="table"
        rowKey="uid"
        :columns="columns"
        :data="dataSource"
        :row-selection="{ selectedRowKeys: assign.uids, onChange: onSelectChange }"
      >
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
        <template slot="logo" slot-scope="text">
          <img :src="text" style="width: 45px;height: 45px;" />
        </template>
        <template slot="uid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span class="uid_fat" @mouseover="handleMouse(record)" @mouseout="handleMouse(record)" @click="copyInfo(record.uid)">
              {{ text.substring(0, 4) + '……' + text.substring(text.split('').length - 4) }}
              <span class="uid_child" v-show="record.showid">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>
        <template slot="operation" slot-scope="text, record">
          <span class="edit_btn" v-if="record.customerUid" @click="clearUserAssign(record)">清除分配</span>
          <span class="edit_btn" v-else @click="singleUserAssign(record)">分配</span>
        </template>
      </s-table>

      <a-modal
        title="分配用户"
        :width="560"
        :visible="assignVisible"
        @cancel="
          () => {
            assignVisible = false;
          }
        "
        @ok="handleOk"
      >
        <div class="input_group">
          <span>选择客服：</span>
          <a-select
            style="width:240px;height:32px"
            show-search
            :filter-option="filterOption"
            :allowClear="true"
            placeholder="请选择客服"
            v-model="assign.customerUid"
          >
            <a-select-option v-for="item in customerList" :key="item.id" :value="item.id">{{ item.username }}</a-select-option>
          </a-select>
        </div>
      </a-modal>
    </div>
  </page-view>
</template>
<script>
import { STable } from '@/components';
import { PageView } from '@/layouts';

import { sipUserList, accountBindCustomer, accountList } from '@api';
import { formatDate, roleCodeToName, customerVisitObj, jsonToArray } from '@/utils/util';
const rolesCustomRender = (value, row, index) => {
  let roleNames = '';
  value.split(',').map((it) => {
    roleNames += roleCodeToName[it].name + ';';
  });
  return roleNames;
};

export default {
  data() {
    return {
      rolesList: [],
      edit: { show: false },
      queryParam: {
        sip: true
      },
      lastPayDay: '',
      lastLoginDay: '',
      assignVisible: false,
      customerList: [],
      customerListObj: {},
      assign: {
        uids: []
      },
      customerVisitArr: jsonToArray(customerVisitObj),
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '用户ID',
          dataIndex: 'uid',
          scopedSlots: { customRender: 'uid' }
        },
        {
          title: '登录名',
          dataIndex: 'username'
        },
        {
          title: '手机号',
          dataIndex: 'mobile'
        },
        {
          title: '角色',
          dataIndex: 'roleNames',
          width: '10%'
          // customRender: rolesCustomRender
        },

        {
          title: '绑定客服',
          dataIndex: 'customerUid',
          customRender: (text) => this.customerListObj[text]
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          customRender: formatDate,
          sorter: (a, b) => a.createTime - b.createTime
        },
        {
          title: '修改时间',
          dataIndex: 'updateTime',
          customRender: formatDate
        },
        {
          title: '最后登录时间',
          dataIndex: 'lastLoginTime',
          customRender: formatDate
        },
        {
          title: '最后付费时间',
          dataIndex: 'lastPayTime',
          customRender: formatDate
        },

        {
          title: '操作',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: (parameter) => {
        if (this.lastPayDay > 0) {
          this.queryParam.lastPayTimeStart = 1;
          this.queryParam.lastPayTimeEnd = new Date().getTime() - Number(this.lastPayDay) * 86400000;
        } else if (this.lastPayDay.trim().length < 1) {
          delete this.queryParam.lastPayTimeEnd;
          delete this.queryParam.lastPayTimeStart;
        } else if (this.lastPayDay == 0) {
          this.queryParam.lastPayTimeEnd = 0;
          this.queryParam.lastPayTimeStart = 0;
        }
        if (this.lastLoginDay > 0) {
          this.queryParam.lastLoginTimeStart = 1;
          this.queryParam.lastLoginTimeEnd = new Date().getTime() - Number(this.lastPayDay) * 86400000;
        } else if (this.lastLoginDay.trim().length < 1) {
          delete this.queryParam.lastLoginTimeEnd;
          delete this.queryParam.lastLoginTimeStart;
        } else if (this.lastLoginDay == 0) {
          this.queryParam.lastLoginTimeEnd = 0;
          this.queryParam.lastLoginTimeStart = 0;
        }
        return accountList('admin', Object.assign(parameter, this.queryParam)).then((result) => {
          result.data.list.forEach((item) => (item.showid = false));
          return result.data;
        });
      }
    };
  },

  created() {
    //修改角色列表
    const array = [];
    const roleObj = {
      FORWARDER: { name: '代发', id: 3 },
      OEM: { name: '贴牌', id: 5 }
    };
    for (let key in roleObj) {
      array.push({ name: roleObj[key].name, value: key });
    }
    this.rolesList = array;
    sipUserList({ roleId: 7 }).then((res) => {
      this.customerList = res.data.list;
      let obj = {};
      res.data.list.forEach((item) => {
        obj[item.id] = item.username;
      });
      this.customerListObj = obj;
    });
  },
  components: {
    PageView,
    STable
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.assign.uids = selectedRowKeys;
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleMouse(record) {
      record.showid = !record.showid;
    },

    // 重置
    resetQuery() {
      this.lastPayDay = '';
      this.lastLoginDay = '';
      this.queryParam = {};
      this.$refs.table.refresh(false);
    },
    //批量分配
    handleBatchAssignUser() {
      this.assignVisible = true;
    },
    //单个分配
    singleUserAssign(record) {
      this.assignVisible = true;
      this.assign.uids = [record.uid];
    },
    handleBatchClearAssign() {
      this.$confirm({
        title: '是否清除所有分配？',
        cancelText: '取消',
        okText: '确定',
        onOk: () => {
          const data = {
            customerUid: '',
            uids: this.assign.uids
          };
          accountBindCustomer(data).then((res) => {
            if (res.success) {
              this.$refs.table.refresh(false);
              this.$message.success('清除成功');
              this.assign.uids = [];
            } else {
              this.$message.error(res.msg);
            }
          });
        },
        onCancel() {}
      });
    },

    handleOk() {
      if (!this.assign.customerUid) {
        return this.$message.error('请先选择客服');
      }
      accountBindCustomer(this.assign).then((res) => {
        if (res.success) {
          this.assignVisible = false;
          this.$refs.table.refresh(false);
          this.$message.success('分配成功');
          this.assign.uids = [];
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    clearUserAssign(record) {
      this.$confirm({
        title: '是否清除分配？',
        cancelText: '取消',
        okText: '确定',
        onOk: () => {
          const data = {
            customerUid: '',
            uids: [record.uid]
          };
          accountBindCustomer(data).then((res) => {
            if (res.success) {
              this.$refs.table.refresh(false);
              this.$message.success('清除成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        },
        onCancel() {}
      });
    }
  },
  computed: {
    title() {
      return this.$route.meta.title;
    }
  }
};
</script>

<style lang="less" scoped>
.user_header_wrapper {
  padding: 24px 56px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  .row {
    margin-left: 0 !important;
  }

  .filter_item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    span {
      width: 70px;
      text-align: right;
    }
    .filter_input {
      width: 240px;
      height: 32px;
      margin-left: 6px;
    }
    .query_btn {
      margin-left: 12px;
    }
  }
}
.user_table_wrapper {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  margin-bottom: 24px;
  font-family: PingFang SC;
  padding: 24px;
}

.input_group {
  display: flex;
  margin-bottom: 24px;
  // align-items: center;
  span {
    margin-top: 5px;
    width: 88px;
    text-align: right;
  }
}
.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
.top5 {
  width: 320px;
  height: 32px;
}
.pagin {
  margin-top: 24px;
  margin-right: 24px;
  text-align: right;
}
.edit_btn {
  cursor: pointer;
  color: #1890ff;
}
</style>
